
const oauth = {
    state: {
        loadingPage: "/loading",
        loadingUrl: "",
        redirectUrl: null,
        wxRedirectUrl: null,
        routerTo: {fullPath: null,
            meta: null,
            name: null,
            params: null,
            path: null,
            query: null
        }
    },

    mutations: {
        SET_REDIRECT_URL: (state, redirectUrl) => {
            state.redirectUrl = redirectUrl
        },
        SET_WX_REDIRECT_URL: (state, wxRedirectUrl) => {
            state.wxRedirectUrl = wxRedirectUrl
        },
        SET_ROUTER_TO: (state, payload) => {
            state.routerTo.fullPath = payload.fullPath
            state.routerTo.meta = payload.meta
            state.routerTo.name = payload.name
            state.routerTo.params = payload.params
            state.routerTo.path = payload.path
            state.routerTo.query = payload.query
        },
    },

    actions: {
        setRedirectUrl: ({ commit, state }, { redirectUrl, wxRedirectUrl, routerTo }) => {
            console.log('执行 actions setRedirectUrl 操作', { commit, state }, { redirectUrl, wxRedirectUrl })
            commit("SET_REDIRECT_URL", redirectUrl);
            commit("SET_WX_REDIRECT_URL", wxRedirectUrl);
            commit("SET_ROUTER_TO", routerTo);
        },
        setWxRedirectUrl: ({ commit, state }, { wxRedirectUrl }) => {
            console.log('执行 actions setWxRedirectUrl 操作', { commit, state }, { wxRedirectUrl })
            commit("SET_WX_REDIRECT_URL", wxRedirectUrl);
        },
    }
}

export default oauth
