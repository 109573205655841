const map = {
    state: {
        mapInfo: {},
    },

    mutations: {
        SET_MAP_INFO: (state, mapInfo) => {
            state.mapInfo = mapInfo
        },
    },

    actions: {

    }
}

export default map
