/* eslint-disable */

import axios from 'axios';
import Vue from 'vue'

import Router from '@/router.js';


// 创建axios实例
const service = axios.create({
  timeout: 600000, // 请求超时时间
  /***如果qs没有stringify,将下面方法打开才能提交form,只在post时生效 **/
  transformRequest: [function (data) {
    // Do whatever you want to transform the data
    let ret = ''
    for (let it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret;
  }]
});

/**=======================================**/
// response 拦截器
service.interceptors.response.use(
    /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中,
   */
  response => {
    let allCookies = document.cookie;
    const res = response.data;
    const headers = response.headers;

    let message = res.msg;
    if (res.status === 5101) {
      // Vue.$vux.toast.show({
      //   text: message,
      //   position:'top',
      //   type:'cancel'
      // });
      // Router.push({
      //   name: "auth",
      // });
    }
    if (res.status === 5001) {
    }
    if (message !== undefined && message !== '' && message !== null) {
      // Vue.$vux.toast.show({
      //   text: message,
      //   position:'top',
      //   type:'cancel'
      // });
    }
    if (res.status !== 200) {
      // Vue.$vux.toast.show({
      //   text: 'colin',
      //   position:'top',
      //   type:'cancel'
      // });
    }
    return response.data;
  },
  /**接口交互错误是处理,如果特殊错误,可对message进行修改**/
  error => {
    let message = error.message;
    if (error.response.data.status === 40301) {
      message = "请登陆";
      Router.push({
        name: "auth",
      });
    } else if (error.response.data.status === 40303) {
      message = "请登陆";
      Router.push({
        name: "auth",
      });
    } else if (error.response.data.status === 40304) {
      message = "没有权限";
      Router.push({
        name: "auth",
      });
    }

    /**错误弹框,仅在element-ui适用,如果使用其他前端框架(如手机端的),要相应进行修改**/
    //测试阶段先注释掉，正式环境根据情况再开放
    Vue.$vux.toast.show({
      text: message,
      // position:'top',
      type:'cancel'

    });
    return Promise.reject(error);
  }
)

export default service;
