import {login, getInfo, logout} from '@/api/admin/login'
import $store from "@/store";

const order = {
    state: {
        product: {},
        products: [],
        mergeProducts: [],
        selectProductAfter: {},
        amount: 0,
        orderUser: {},
        selectEntityAfter: {},
        cate: {},
        searchParamDebt: {}
    },

    mutations: {
        SET_SEARCH_PARAM_DEBT: (state, searchParam) => {
            state.searchParamDebt = searchParam
        },
        SET_USER: (state, orderUser) => {
            state.orderUser = orderUser
        },
        SET_PRODUCT: (state, product) => {
            state.product = product
        },
        SET_CATE: (state, cate) => {
            state.cate = cate
        },
        SET_PRODUCTS: (state, products) => {
            console.log('SET_PRODUCTS products', products)
            console.log('SET_PRODUCTS state.mergeProducts', state.mergeProducts)
            let amount = 0;
            products.forEach((item) => {
                // 修正数据
                if (item.id === undefined || item.id === null) {
                    item.id = item.productId
                }

                amount += item.totalPrice
                if ($store.state.order.selectProductAfter.type === 'purchase') {
                    item.storeProductAttrValues.forEach(skuItem => {
                        if (skuItem.zNum === undefined || skuItem.zNum <= 0) {
                            skuItem.zNum = skuItem.number
                        }
                    })
                }
                if ($store.state.order.selectProductAfter.type === 'order') {
                    item.storeProductAttrValues.forEach(skuItem => {
                        if (skuItem.sNum === undefined || skuItem.sNum <= 0) {
                            skuItem.sNum = skuItem.number
                        }
                    })
                }
            })
            state.products = products
            state.amount = amount * 100

            if (state.mergeProducts.length > 0) {
                state.mergeProducts.forEach(item => {
                    // 修正数据
                    if (item.id === undefined || item.id === null) {
                        item.id = item.productId
                    }
                })
            }

            // 商品选择叠加
            if (state.mergeProducts.length === 0) {
                state.mergeProducts = products
            } else {
                let yList = []
                let wList = []
                products.forEach(item => {
                    let index = state.mergeProducts.findIndex(oldItem => {
                        return item.id === oldItem.id
                    })
                    if (index === -1) {
                        // 没找到
                        wList.push({...item})
                    } else {
                        yList.push({...item})
                    }
                })
                // 将新选择商品加入
                if (wList.length !== 0) {
                    wList.forEach(item => {
                        state.mergeProducts.push(item)
                    })
                }
                // 将新选择规格加入
                if (yList.length !== 0) {
                    yList.forEach(item => {
                        let obj = state.mergeProducts.find(oldItem => {
                            return item.id === oldItem.id
                        })
                        if (obj !== null) {
                            item.storeProductAttrValues.forEach(skuItem => {
                                let skuObj = obj.storeProductAttrValues.find(oldSkuItem => {
                                    return skuItem.productId === oldSkuItem.productId && skuItem.sku === oldSkuItem.sku
                                })
                                if (skuObj === undefined) {
                                    obj.storeProductAttrValues.push(skuItem)
                                }
                            })
                        }
                    })
                }
            }
        },
        setSelectProductAfter: (state, va) => {
            state.selectProductAfter = va
        },
        setSelectEntityAfter: (state, va) => {
            state.selectEntityAfter = va
        }
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            console.log(userInfo)
            console.log('无验证码登录成功开始')
            return new Promise((resolve, reject) => {
                login(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(res => {
                    console.log('无验证码登录成功')
                    console.log(res)
                    // setToken(res.token, rememberMe)
                    console.log(res.token)
                    commit('SET_TOKEN', res.token)
                    setUserInfo(res.user, commit)
                    // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
                    commit('SET_LOAD_MENUS', true)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({commit}) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    setUserInfo(res, commit)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        LogOut({commit}) {
            return new Promise((resolve, reject) => {
                logout().then(res => {
                    logOut(commit)
                    resolve()
                }).catch(error => {
                    logOut(commit)
                    reject(error)
                })
            })
        },

        updateLoadMenus({commit}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD_MENUS', false)
            })
        },
        changeAuthorizationPage({
                                    state,
                                    commit
                                }, index) {
            commit("updateAuthorizationPage", index);
        },
        changeAuthorization({
                                state,
                                commit
                            }, index) {
            commit("updateAuthorization", index);
        },
        assignmentToken({commit}, token) {
            commit("SET_TOKEN", token);
        },
    }
}

export default order
