import * as AuthUtil from '@/utils/admin/auth'

const im = {
    state: {
        socketAction: '',
        customerList: [],
        selectChatUserId: null,
        refreshContactList: [],
        refreshContactAction: null,
        watchUpdateContact: null,
        watchAddContact: null,
        watchRemoveContact: null,
        readMessageIdList: [],
        vertxNoticeText: null,
        vertxUserNoticePopShow: false
    },
    mutations: {
        catchSocketAction(state, data) {
            state.socketAction = data;
        },
        setCustomerList(state, data) {
            state.customerList = data;
        },
        setSelectChatUserId(state, data) {
            console.log('mutations setSelectChatUserId', data)
            state.selectChatUserId = data;
        },
        setRefreshContactList(state, data) {
            console.log('mutations setRefreshContactList', data)
            state.refreshContactList = data;
        },
        setRefreshContactAction(state, data) {
            state.refreshContactAction = data;
        },
        setWatchUpdateContact(state, data) {
            state.watchUpdateContact = data;
        },
        setWatchAddContact(state, data) {
            state.watchAddContact = data;
        },
        setWatchRemoveContact(state, data) {
            state.watchRemoveContact = data;
        },
        setReadMessageIdList(state, data) {
            state.readMessageIdList = data;
        },
        setVertxNoticeText(state, data) {
            console.log('mutations setVertxNoticeText', data)
            state.vertxNoticeText = data;
        },
        setVertxUserNoticePopShow(state, data) {
            state.vertxUserNoticePopShow = data;
        },
    },
    actions: {
        doLineStatus: ({commit, state}, {data}) => {
            console.log('执行 actions doLineStatus 操作', {commit, state}, {data})
            commit("setWatchUpdateContact", data)

            let updateCustomerList = state.customerList
            updateCustomerList.forEach(i => {
                if (i.id === data.id) {
                    i.online = data.online
                }
            })
            console.log('执行 actions doLineStatus 操作数据', updateCustomerList)
            commit("setCustomerList", updateCustomerList)
        },
        doLastMessContact: ({commit, state}, {data}) => {
            console.log('执行 actions doLastMessContact 操作', {commit, state}, {data}, AuthUtil.isLogin())
            // 认证用户才去更新列表 管理员
            if (!AuthUtil.isLogin()) {
                return
            }
            commit("setWatchUpdateContact", data)
            let updateCustomerList = state.customerList
            updateCustomerList.forEach(i => {
                if (i.id === data.id) {
                    i.lastContent = data.lastContent
                    i.lastSendTime = data.lastSendTime
                }
            })
            console.log('执行 actions doLastMessContact 操作数据', updateCustomerList)
            commit("setCustomerList", updateCustomerList)
        },
        doAddContact: ({commit, state}, {data}) => {
            console.log('执行 actions doAddContact 操作', {commit, state}, {data}, AuthUtil.isLogin())
            if (!AuthUtil.isLogin()) {
                return
            }
            commit("setWatchAddContact", data)
            console.log('执行 actions doAddContact 操作数据')
            // 认证用户才去更新列表 管理员
            commit("setRefreshContactAction", 1)
        },
        doAddNoReadNum: ({commit, state}, {userId}) => {
            console.log('执行 actions doNoReadNum 操作', {commit, state}, {userId})
            let updateCustomerList = state.customerList
            updateCustomerList.forEach(i => {
                if (i.id === userId) {
                    if (i.unread === undefined || i.unread === null) {
                        i.unread = 1
                    } else {
                        i.unread++
                    }
                }
            })
            console.log('执行 actions doNoReadNum 操作数据', updateCustomerList)
            commit("setCustomerList", updateCustomerList)
        },
        doNullReadNum: ({commit, state}, {userId}) => {
            console.log('执行 actions doNullReadNum 操作', {commit, state}, {userId})
            let updateCustomerList = state.customerList
            updateCustomerList.forEach(i => {
                if (i.id === userId) {
                    i.unread = null
                }
            })
            console.log('执行 actions doNullReadNum 操作数据', updateCustomerList)
            commit("setCustomerList", updateCustomerList)
        },
        doMessageRead: ({commit, state}, {data}) => {
            console.log('执行 actions doMessageRead 操作', {commit, state}, {data})
            commit("setReadMessageIdList", data)
        },
    }
}
export default im
