import Vue from 'vue';
import Router from 'vue-router';
import $store from "@/store";
import toLogin from "@/libs/login";
import {getBaseUrl, hasPermissionIn, parseQuery} from "@/utils";
import {handleLogin} from "@/utils/admin";
import {createConnect} from "@/utils/socket/vertx";

Vue.use(Router);

const routes = [
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index'),
    meta: {
      title: '商品列表',
      requiresAuth: true
    }
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('./view/cart'),
    meta: {
      title: '商品列表'
    }
  },
  {
    name: 'wholesale',
    path: '/wholesale/:userUuid',
    component: () => import('./view/wholesale'),
    meta: {
      title: '分销价格'
    }
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品列表',
      requiresAuth: true
    }
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '添加用户',
      keepAlive: true,
      requiresAuth: true
    }
  },
  {
    name: 'userList',
    path: '/user/list',
    component: () => import('./view/user/search'),
    meta: {
      title: '用户列表',
      requiresAuth: true
    }
  },
  {
    name: 'userParticulars',
    path: '/user/particulars/:userId',
    component: () => import('./view/user/particulars'),
    meta: {
      title: '用户详情',
      requiresAuth: true
    }
  },
  {
    name: 'userOrder',
    path: '/user/order/:userId',
    component: () => import('./view/order/searchForUser'),
    meta: {
      title: '用户订单列表',
      requiresAuth: true
    }
  },
  {
    name: 'price',
    path: '/user/price/:userId',
    component: () => import('./view/user/searchForPrice'),
    meta: {
      title: '用户价格列表',
      requiresAuth: true
    }
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('./view/user/order'),
    meta: {
      title: '添加订单',
      requiresAuth: true
    }
  },
  {
    name: 'orderList',
    path: '/order/list',
    component: () => import('./view/order/list'),
    meta: {
      title: '订单列表',
      requiresAuth: true
    }
  },
  {
    name: 'orderInfo',
    path: '/order/info/:orderId',
    component: () => import('./view/order/info'),
    meta: {
      title: '订单详情',
      requiresAuth: true
    }
  },
  {
    name: 'search',
    path: '/order/search',
    component: () => import('./view/order/search'),
    meta: {
      title: '订单列表搜索',
      requiresAuth: true
    }
  },
  {
    name: 'orderProduct',
    path: '/order/product/:userId',
    component: () => import('./view/order/product'),
    meta: {
      title: '选择商品',
      requiresAuth: true
    }
  },
  {
    name: 'orderUser',
    path: '/order/user',
    component: () => import('./view/order/user'),
    meta: {
      title: '选择用户',
      requiresAuth: true
    }
  },
  {
    name: 'price',
    path: '/price/list',
    component: () => import('./view/price/list'),
    meta: {
      title: '价格列表',
      requiresAuth: true
    }
  },
  {
    name: 'priceSearch',
    path: '/price/search',
    component: () => import('./view/price/search'),
    meta: {
      title: '价格搜索列表',
      requiresAuth: true
    }
  },
  {
    name: 'priceSearch',
    path: '/test',
    component: () => import('./view/user/order111'),
    meta: {
      title: '价格搜索列表',
      requiresAuth: true
    }
  },
  {
    name: 'map',
    path: '/map',
    component: () => import('./view/map/index'),
    meta: {
      title: '地图',
      requiresAuth: true
    }
  },
  {
    name: 'loading',
    path: '/loading',
    component: () => import('./view/loading'),
    meta: {
      title: '价格列表'
    }
  },
  {
    name: 'debtList',
    path: '/order/debt/search',
    component: () => import('./view/order/searchForDebt'),
    meta: {
      title: '欠账单列表',
      requiresAuth: true
    }
  },
  {
    name: 'debtInfo',
    path: '/order/debt/info/:debtId',
    component: () => import('./view/order/debtInfo'),
    meta: {
      title: '欠账单详情',
      requiresAuth: true
    }
  },
  {
    name: 'mergeDebt',
    path: '/order/debt/merge/:userId/:checkedDebtId',
    component: () => import('./view/order/mergeDebt'),
    meta: {
      title: '欠账单详情',
      requiresAuth: true
    }
  },
  {
    name: 'unsalableProduct',
    path: '/product/stock/:tag',
    component: () => import('./view/product/stock.vue'),
    meta: {
      title: '滞销商品',
      requiresAuth: true,
      requiredPermissions: ["ROLE_STAFF", 'YXSYSTEMGROUPDATA_ALL'],
    }
  },
  {
    name: 'stock',
    path: '/product/stock',
    component: () => import('./view/product/stock.vue'),
    meta: {
      title: '库存管理',
      requiresAuth: true,
      requiredPermissions: ["ROLE_STAFF", 'YXSYSTEMGROUPDATA_ALL'],
    }
  },
  {
    name: 'stockOperateLog',
    path: '/product/stockOperateLog',
    component: () => import('./view/product/stockOperateLog.vue'),
    meta: {
      title: '库存操作日志管理',
      requiresAuth: true,
      requiredPermissions: ["ROLE_STAFF", 'YXSYSTEMGROUPDATA_ALL'],
    }
  },
  {
    name: 'saleLine',
    path: '/chart/saleLine',
    component: () => import('./view/chart/saleLine'),
    meta: {
      title: '图表过去12个月每月销售额对比',
      requiresAuth: true
    }
  },
  {
    name: 'creditColumn',
    path: '/chart/creditColumn',
    component: () => import('./view/chart/creditColumn'),
    meta: {
      title: '图表欠账累计柱状图',
      requiresAuth: true
    }
  },
  {
    name: 'websocketGreetingsAdmin',
    path: '/websocket/websocketGreetings/admin',
    component: () => import('./view/websocket/WebsocketGreetings'),
    meta: {
      title: '客服人员',
      requiresAuth: true
    }
  },
  {
    name: 'websocketGreetings',
    path: '/websocket/websocketGreetings',
    component: () => import('./view/websocket/WebsocketGreetings'),
    meta: {
      title: '客服',
    }
  },
  {
    name: 'purchaseList',
    path: '/purchase/list',
    component: () => import('./view/purchase/list.vue'),
    meta: {
      title: '进货列表',
      requiresAuth: true
    }
  },
  {
    name: 'purchase',
    path: '/purchase',
    component: () => import('./view/purchase/index.vue'),
    meta: {
      title: '进货',
      requiresAuth: true
    }
  },
  {
    name: 'addFactory',
    path: '/purchase/addFactory',
    component: () => import('./view/purchase/addFactory.vue'),
    meta: {
      title: '增加厂家',
      requiresAuth: true
    }
  },
  {
    name: 'factoryList',
    path: '/factory/list',
    component: () => import('./view/factory/list'),
    meta: {
      title: '厂家列表',
      requiresAuth: true
    }
  },
  {
    name: 'factoryParticulars',
    path: '/factory/particulars/:uuid',
    component: () => import('./view/factory/particulars'),
    meta: {
      title: '厂家详情',
      requiresAuth: true
    }
  },
  {
    name: 'purchasePrice',
    path: '/price/purchaseList',
    component: () => import('./view/price/purchaseList.vue'),
    meta: {
      title: '进货价格',
      requiresAuth: true
    }
  },
  {
    name: 'debtRanking',
    path: '/statistics/debtRanking',
    component: () => import('./view/statistics/debtRanking.vue'),
    meta: {
      title: '欠账排行',
      requiresAuth: true
    }
  },
  {
    name: 'returnList',
    path: '/return/list',
    component: () => import('./view/return/list.vue'),
    meta: {
      title: '退货列表',
      requiresAuth: true
    }
  },
  {
    name: 'return',
    path: '/return',
    component: () => import('./view/return/index.vue'),
    meta: {
      title: '退货',
      requiresAuth: true
    }
  },
  {
    name: 'selectOrder',
    path: '/selectOrder/:userId',
    component: () => import('./view/order/select.vue'),
    meta: {
      title: '退货',
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: '/cart'
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

const { back, replace } = router;

router.back = function() {
  this.isBack = true;
  back.call(router);
};
router.replace = function(...args) {
  this.isReplace = true;
  replace.call(router, ...args);
};

router.beforeEach((to, form, next) => {
  const { title, backgroundColor, footer, home, auth } = to.meta;
  console.log(to.name, form.name);
  if (auth === true && !$store.state.user.token) {
    if (form.name === "Login") return;
    return toLogin(true, to.fullPath);
  }
  document.title = title || process.env.VUE_APP_NAME || "鸣鹿商城";
  if (auth) {
    $store.dispatch("USERINFO").then(() => {
      next();
    });
  } else next();
});

router.beforeEach((to, from, next) => {
  console.log('to from next', to, from, next)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // false 表示需要登录
    console.log('window.location.href', window.location.href)
    // 是否是正在登录
    console.log('store', $store)
    console.log('当前路由需要认证。检查是否需要登录，!$store.state.user.token', !$store.state.user.token)
    // 如果路由需要认证，则检查是否已经登录，如果没有，导航到登录页面
    if (!$store.state.user.token) {
      const params = parseQuery()
      console.log('当前url的参数，parseQuery()：', params)
      if (!$store.state.user.token &&
          params !== undefined && params !== null &&
          params.token !== undefined && params.token !== null) {
        console.log("路径url上有token参数，为后台服务器重定向回来")
        const baseUrl = getBaseUrl()
        console.log('baseUrl', baseUrl)
        console.log('开始执行登录 dispatch login操作')
        $store.dispatch("login", {token: params.token}).then(() => {
          console.log('store', $store)
          createConnect()
          window.location.href = baseUrl;
          next({
            path: to.path,
          })
        }).catch((err) => next(err))
      } else {
        // 没有 token 就开始认证
        console.log('location.href', location.href)
        console.log('location.href to.fullPath', location.origin + '/#' + to.fullPath)

        // redirectUrl 为后台服务器认证完重定向的路径 wxRedirectUrl 为微信js插件拉取服务器配置校验的路径
        $store.dispatch("setRedirectUrl", {
          redirectUrl: location.origin + '/#' + to.fullPath,
          wxRedirectUrl: process.env.VUE_APP_LOADING_URL,
          routerTo: to
        }).then(() => {
          console.log('执行 actions setRedirectUrl 操成功', $store, process.env.VUE_APP_LOADING_PAGE)
          handleLogin(next)
        }).catch((err) => next(err))
      }
    } else if (to.matched.some(
        (record) =>
            record.meta.requiredPermissions &&
            !hasPermissionIn(record.meta.requiredPermissions)
    )) {
      console.log('当前用户没有此菜单的权限', to.meta.requiredPermissions)
      next({
        path: "/cart",
      });
    } else {
      next();
    }
  } else {
    next(); // 确保在其他情况下调用 next()!
  }
});

export {
  router
};
