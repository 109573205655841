import axios from "axios";
import $store from "../store";
import toLogin from "@/libs/login";
import {Notify} from "vant";
import {handleLogin} from "@/utils/admin";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 3000
});

instance.interceptors.response.use(
    response => {
        // 定时刷新access-token
        return response;
    },
    error => {
        console.log('utils-request 异常 error', error)
        if (error.toString() == "Error: Network Error") {
            toLogin();
            return Promise.reject({
                msg: "请检查后台api接口服务是否启动",
                toLogin: true
            });
        }
        if (error.response.data.status == 401) {
            toLogin();
            return Promise.reject({msg: "未登录", toLogin: true});
        }
        Notify({ type: 'danger', message: '服务器异常' });
        return Promise.reject({
            msg: error.response.data.msg
                ? error.response.data.msg
                : error.response.data.message,
            res: error.response
        });
    }
);

const defaultOpt = {login: true};

function baseRequest(options) {
    console.log('utils-request 设置请求拦截')
    window.console.log($store.state)
    const token = $store.state.user.token;
    const headers = options.headers || {};
    if (token) {
        headers["Authorization"] = token;
    }

    options.headers = headers;
    if (options.login && !token) {
        console.log('utils-request 需要登录。执行登录请求')
        handleLogin()
        return Promise.reject({msg: "未登录", toLogin: true});
    }

    return instance(options).then(res => {
        console.log('utils-request instance', res)
        const data = res.data || {};
        if (res.status !== 200)
            return Promise.reject({msg: "请求失败", res, data});
        if ([401, 403].indexOf(data.status) !== -1) {
            toLogin();
            return Promise.reject({msg: res.data.msg, res, data, toLogin: true});
        } else if (data.status === 200) {
            return Promise.resolve(data, res);
        } else if (res.status === 200) {
            return Promise.resolve(data, res);
        } else {
            return Promise.reject({msg: res.data.msg, res, data});
        }
    });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ["post", "put", "patch"].reduce((request, method) => {
    /**
     *
     * @param url string 接口地址
     * @param data object get参数
     * @param options object axios 配置项
     * @returns {AxiosPromise}
     */
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(
            Object.assign({url, data, method}, defaultOpt, options)
        );
    };
    return request;
}, {});

["get", "delete", "head"].forEach(method => {
    /**
     *
     * @param url string 接口地址
     * @param params object get参数
     * @param options object axios 配置项
     * @returns {AxiosPromise}
     */
    request[method] = (url, params = {}, options = {}) => {
        return baseRequest(
            Object.assign({url, params, method}, defaultOpt, options)
        );
    };
});

export default request;
