import request from '@/utils/admin/request'
import fetch from "@/api/fetch";
import {VUE_ADMIN_API_URL} from "@/utils";

export function login(username, password, code, uuid) {
    return request({
        url: 'auth/login-no-code',
        method: 'post',
        data: {
            username,
            password,
            code,
            uuid
        }
    })
}

export function auth(code) {
    return request({
        url: '/api/wechat/auth',
        method: 'get',
        data: code,
    })
}

export function getInfo() {
    return request({
        url: 'auth/info',
        method: 'get'
    })
}

export function getCodeImg() {
    return request({
        url: 'auth/code',
        method: 'get'
    })
}

export function logout() {
    return request({
        url: 'auth/logout',
        method: 'delete'
    })
}

export function addUser(object) {
    return request({
        url: 'charge-account/add-user',
        method: 'post',
        data: object,
    })
}

export function getAccountUsers() {
    return request({
        url: 'api/charge-account/users',
        method: 'get'
    });
}

export function getAccountOrder(userId) {
    return request({
        url: '/charge-account?userId=' + userId,
        method: 'get'
    });
}


export function addAccount(object) {
    return request({
        url: '/charge-account',
        method: 'post',
        data: object
    });
}

export function test() {
    return request({
        url: '/charge-account/test',
        method: 'post'
    });
}
