/* eslint-disable */
import fetch from '@/api/fetch'
import request from "@/utils/admin/request";

const VUE_ADMIN_API_URL = process.env.VUE_APP_ADMIN_API_URL
const VUE_APP_API_URL = process.env.VUE_APP_API_URL
const VUE_ADMIN_BASE_API = process.env.VUE_APP_ADMIN_BASE_API

export function getUserInfo() {
    return request({
        url: VUE_ADMIN_BASE_API + 'auth/info',
        method: 'get'
    })
}

export function getProducts(data) {
    return fetch({
        url: VUE_APP_API_URL + '/product-show/products?' + data,
        method: 'get',
        data: data
    });
}

export function getItems() {
    console.log(VUE_APP_API_URL)
    return fetch({
        url: VUE_APP_API_URL + '/product-show/items',
        method: 'get'
    });
}

export function getWholesaleItems(pathVariable) {
    return fetch({
        url: VUE_APP_API_URL + '/product-show/wholesale-items/' + pathVariable,
        method: 'get'
    });
}

export function getWholesaleProducts(pathVariable, data) {
    return fetch({
        url: VUE_APP_API_URL + '/product-show/wholesale-products/' + pathVariable + '?' + data,
        method: 'get'
    });
}

export function getAccountItems() {
    return fetch({
        url: VUE_ADMIN_API_URL + '/charge-account/items',
        method: 'get'
    });
}

export function getAccountUsers() {
    return fetch({
        url: VUE_ADMIN_API_URL + '/charge-account/users',
        method: 'get'
    });
}

export function addAccount(data) {
    return fetch({
        url: VUE_ADMIN_API_URL + '/charge-account',
        method: 'post',
        data: data
    });
}

export function getCity() {
    return fetch({
        url: VUE_APP_API_URL + '/city_list',
        method: 'get',
    })
}





