// import router from "../router";
import store from "../store";
import {isWeixin} from "@/utils";
import {oAuth} from "@/libs/wechat";
import $store from "@/store";

export default function toLogin(push, backUrl) {
    store.commit("LOGOUT");
    window.console.log(backUrl)
    // const {fullPath, name} = this.router.path;
    // cookie.set("login_back_url", backUrl || fullPath);

    // oAuth();

    // 先要获取当前用户的 openid
    // 然后用openid 获取特定的用户名密码
    // 然后应用已有的后台认证体系
    if (isWeixin()) {
        window.console.log('isWeixin')
        oAuth();
    } else {
        if (name !== "Login") {
            // push
            //     ? router.push({path: "/login"})
            //     : router.replace({path: "/login"});
        }
    }
}

export const handleLoginDev = () => {
    console.log('————————')
    console.log('开始登录 开发环境')
    console.log('————————')
    const state = encodeURIComponent($store.state.oauth.redirectUrl);
    console.log('state参数', state)
    location.href = `http://localhost:8001/api/wechat/auth-dev?state=${state}&code=1`;
}

