import {login, getInfo, logout} from '@/api/admin/login'
import {getUserInfo} from "@/api/serverData/getServerData";

const user = {
    state: {
        token: null,
        user: {},
        authUser: {},
        roles: [],
        // 第一次加载菜单时用到
        loadMenus: false,
        // 是否已经在授权页面
        isAuthorizationPage: false,
        // 是否授权
        isAuthorization: false,
        accountUser: {},
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_ACCOUNT_USER_ADD: (state, accountUser) => {
            state.accountUser = accountUser
        },
        SET_AUTH_USER: (state, user) => {
            state.authUser = user
        },
        SET_USER: (state, user) => {
            state.user = user
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_LOAD_MENUS: (state, loadMenus) => {
            state.loadMenus = loadMenus
        },
        updateAuthorizationPage(state, isAuthorizationPage) {
            state.isAuthorizationPage = isAuthorizationPage;
        },
        updateAuthorization(state, isAuthorization) {
            state.isAuthorization = isAuthorization;
        },
        loginFail: (state, payload) => {
            state.token = null;
        },
    },

    actions: {
        login: ({ commit, state }, { token, userInfo }) => {
            console.log('执行 actions login 操作', { commit, state }, { token, userInfo })
            commit("SET_TOKEN", token);
            commit("SET_REDIRECT_URL", null);
            sessionStorage.setItem("auth", token);
            console.log('执行 actions login 获取用户信息操作')
            return getUserInfo()
                .then((res) => {
                    console.log('请求用户信息响应', res)
                    setUserInfo(res, commit)
                    return Promise.resolve();
                })
                .catch((err) => {
                    commit(
                        "loginFail",
                        "用户名登录失败"
                    );
                    return Promise.reject(err);
                });
        },
        // 登录
        Login({commit}, userInfo) {
            console.log(userInfo)
            console.log('无验证码登录成功开始')
            return new Promise((resolve, reject) => {
                login(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(res => {
                    console.log('无验证码登录成功')
                    console.log(res)
                    // setToken(res.token, rememberMe)
                    console.log(res.token)
                    commit('SET_TOKEN', res.token)
                    setUserInfo(res.user, commit)
                    // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
                    commit('SET_LOAD_MENUS', true)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({commit}) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    setUserInfo(res, commit)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        LogOut({commit}) {
            return new Promise((resolve, reject) => {
                logout().then(res => {
                    logOut(commit)
                    resolve()
                }).catch(error => {
                    logOut(commit)
                    reject(error)
                })
            })
        },

        updateLoadMenus({commit}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD_MENUS', false)
            })
        },
        changeAuthorizationPage({
                                    state,
                                    commit
                                }, index) {
            commit("updateAuthorizationPage", index);
        },
        changeAuthorization({
                                state,
                                commit
                            }, index) {
            commit("updateAuthorization", index);
        },
        assignmentToken({commit}, token) {
            commit("SET_TOKEN", token);
        },
    }
}

export const logOut = (commit) => {
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    removeToken()
}

export const setUserInfo = (res, commit) => {
    // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
    if (res.roles.length === 0) {
        commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT'])
    } else {
        commit('SET_ROLES', res.roles)
    }
    commit('SET_AUTH_USER', res)
}

export default user
