import Vue from 'vue';
import App from './App';
import store from "./store";
import { router } from './router';
import VueAMap from 'vue-amap';
import LemonIMUI from 'lemon-imui';
import "lemon-imui/dist/index.css";

import { Button, Cell, CellGroup, Icon,  Image as VanImage, Col, Row, Popup, Toast} from 'vant';
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Col);
Vue.use(Row)
Vue.use(Popup)
Vue.use(Toast)

import {Loading, Notify} from 'vant'
Vue.use(Loading)
Vue.use(Notify)

// import { Tab, Tabs, RadioGroup, Radio, Checkbox, CheckboxGroup, Collapse, CollapseItem, NavBar, Search, List, Empty, PullRefresh, Form, DropdownMenu, DropdownItem, Step, Steps, NoticeBar, Grid, GridItem, Switch, Tag, Badge} from 'vant';
// Vue.use(Tab);
// Vue.use(Tabs);
// Vue.use(RadioGroup);
// Vue.use(Radio);
// Vue.use(CheckboxGroup);
// Vue.use(Checkbox);
// Vue.use(Collapse);
// Vue.use(CollapseItem);
// Vue.use(NavBar);
// Vue.use(Search);
// Vue.use(List);
// Vue.use(Empty);
// Vue.use(PullRefresh);
// Vue.use(Form);
// Vue.use(Field);
// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
// Vue.use(Step);
// Vue.use(Steps);
// Vue.use(NoticeBar);
// Vue.use(Grid);
// Vue.use(GridItem);
// Vue.use(Switch);
// Vue.use(Tag);
// Vue.use(Badge);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.use(VueAMap);
Vue.use(LemonIMUI)

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '0751d24e7a1064112fa93511ddc5aa2d',
  // 插件集合
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation'
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});

// AmapVue.config.key = '0751d24e7a1064112fa93511ddc5aa2d'
// Vue.use(AmapVue)

let vue = new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
Vue.config.ignoredElements = ['wx-open-launch-weapp']

import VueBus from 'vue-bus'
Vue.use(VueBus);

export default vue
