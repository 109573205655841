// import Cookies from 'js-cookie'
import $store from "@/store";
import {getBaseUrl, hasPermissionIn, parseQuery, generateRandId} from "@/utils";
import {handleLoginForPage} from "@/utils/admin/index";


// const TokenKey = Config.TokenKey
export function getImChatUser() {
    console.log('获取im用户 $store.getters.authUser', $store.getters.authUser)
    console.log('获取im用户 $store.getters.authUser.id', $store.getters.authUser.id)

    let imChatUser;
    if ($store.getters.authUser !== undefined && $store.getters.authUser !== null && $store.getters.authUser.id) {
        const authUser = $store.getters.authUser
        return {
            id: authUser.id + '',
            displayName: authUser.nickName,
            avatar: authUser.avatar
        }
    }
    try {
        imChatUser = JSON.parse(localStorage.getItem("imChatUser") || null)
    } catch (e) {
        imChatUser = null
    }

    console.log('获取im用户 localStorage.getItem', imChatUser)

    if (imChatUser !== undefined && imChatUser !== null) {
        return imChatUser
    }
    console.log('获取im用户 generateRandId', generateRandId)

    imChatUser = {
        id: generateRandId(),
        displayName: '正在线上发呆的小可爱',
        avatar: 'https://p.qqan.com/up/2021-1/20211411391666.jpg'
    }
    console.log('获取im用户 generateRandId', imChatUser)
    localStorage.setItem("imChatUser", JSON.stringify(imChatUser))
    return imChatUser
}

export function isLogin() {
    console.log('$store.getters.authUser', $store.getters.authUser.id)
    return $store.getters.authUser !== undefined && $store.getters.authUser !== null && $store.getters.authUser.id !== undefined && $store.getters.authUser.id !== null
}


export function curUserId() {
    if ($store.getters.authUser !== undefined && $store.getters.authUser !== null) {
        console.log('curUserId 获取当前用户id: ', $store.getters.authUser.id);
        return $store.getters.authUser.id
    }
    return null
}

/** 针对单独页面的授权 */
export function loginForPagePromise() {
    return new Promise((resolve, reject) => {
        loginForPage()
        resolve(true)
    })
}
export function loginForPage() {
    // 如果路由需要认证，则检查是否已经登录，如果没有，导航到登录页面
    if ($store.state.user.token) {
        return
    }
    const params = parseQuery()
    console.log('当前url的参数，parseQuery()：', params)
    // 说明是服务器返回的 已经通过认证 执行登录操作
    if (!$store.state.user.token &&
        params !== undefined && params !== null &&
        params.token !== undefined && params.token !== null) {
        console.log("路径url上有token参数，为后台服务器重定向回来")
        const baseUrl = getBaseUrl()
        console.log('baseUrl', baseUrl)
        console.log('开始执行登录 dispatch login操作')
        $store.dispatch("login", {token: params.token}).then(() => {
            console.log('store', $store)
            window.location.href = baseUrl;
            // window.location.reload();
            // next({
            //     path: to.path,
            // })
            // return new Promise((resolve, reject) => {
            //     loginAPI(userInfo).then(res => {
            //         const data = res.data || data
            //         commit('SET_AUTH', data)
            //         commit('SET_USERINFO', data.userInfo)
            //         resolve(res)
            //     }).catch(error => {
            //         console.log(error);
            //         dispatch('LogOut')
            //         reject(error)
            //     })
            // })

            // return new Promise((resolve, reject) => {
            //     logoutAPI().then(() => {
            //         /** flush 清空localStorage .rm('authToken') 按照key清除 */
            //         Lockr.rm('authToken');
            //         Lockr.rm('sessionId');
            //         removeAuth()
            //         resetRouter()
            //         resolve()
            //     }).catch(error => {
            //         reject(error)
            //     })
            // })
        }).catch((err) => console.log(err))
    } else {
        // 没有 token 就开始认证
        console.log('location.href', location.href)
        console.log('location.href to.fullPath', location)

        // redirectUrl 为后台服务器认证完重定向的路径 wxRedirectUrl 为微信js插件拉取服务器配置校验的路径
        // 这里是不是方法本地更靠谱些
        $store.dispatch("setRedirectUrl", {
            redirectUrl: location.origin + '/' + location.hash,
            wxRedirectUrl: location.href,
            routerTo: location.hash
        }).then(() => {
            console.log('执行 actions setRedirectUrl 操成功', $store, process.env.VUE_APP_LOADING_PAGE)
            handleLoginForPage()
        }).catch((err) => console.log(err))
    }
}

// export function getToken() {
//   return $store.state.app.token;

// return Cookies.get(TokenKey)
// }
//
// export function setToken(token, rememberMe) {
//   if (rememberMe) {
//     return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
//   } else return Cookies.set(TokenKey, token)
// }
//
// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }
