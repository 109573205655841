import JSEncryptL from 'encryptlong'
// encryptlong是基于jsencrypt扩展的长文本分段加解密功能。
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = ''

const privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAKTrFa0AZWUR/msAnp3Z4dpryp8Aon3GQFACq+RcjwS56LwQsWLbfnJ1ttMPnrPeiYtrGWZKE5E3zVk10QwvcpYcm/l7BJin8McIVGEhBFOzKP64tayht53HX20E3cGEwjSVKCT+RH0oUwZUzumcXDj+OiOWrVuY6BYF/X7cXjrnAgMBAAECgYBI1NMNRGwC7yfi/HKQNo1SJyFkB+U602dKjmt4HdJBXZLlHyAfjtywUsELQ84sFD69Fe6xKEys6K5k0+n+ziN1Zy/njUDJZ/0+uluEJZoAw+/BUQpyYh6NYsKn+cDNwYOKnwAMST4DAE/VaxigttAEINLGNVGrP+fN3L0hNeAAxQJBAM2vYsGfyZtWehjfUX0jjP/n1wVXtD+imV70jHOfb7a4h8zvn5ikat8JIGyLggv18WKP234Ipcr5o8QcwUAa72UCQQDNQsKhR2thQzNfP6ZXottoPSQ+d7ON4RnIpcwDGGwrWemLFzQRYtfkcCIttv1o6tMNiKJuQaYKsCgWDykLEXpbAkEAngV2y+clDtC7cUxw4MFlXF4T1ijxdEpJpqgfF92QCENxIcOfS7hV2xC59mqf+Ox1DRIUwZmHNo/gf5TP655F+QJAbHKzZobXgyIASHjWqELRbbewKJkR8wpVlx+SNKh4rkbpD6YIyo7Qxk9An6qpJP+81s+0ElD5K3h7FXyZENIBNQJBAMvaWjkVt1xNyZYdMRcf/AM8Og8+M0HbqO6gvMa+JpgOnJMDsgzq9ucXkVJcr2IW1zcMIZuuw72w9QDQ6Q3Mbrc='

// 加密 有效 但数据量大后端解密失败
export function encryptL(txt) {
  const encryptor = new JSEncryptL()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encryptLong(txt) // 对需要加密的数据进行加密
}

// 解密 有效
export function decryptL(txt) {
  const encryptor = new JSEncryptL()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decryptLong(txt)
}

export function getDesKI(txt) {
  const kiStr = decryptL(txt)
  return kiStr.split(',')
}

export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decrypt(txt)
}

// 解密
export function decryptUserInfo(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKeyUserInfo)
  return encryptor.decrypt(txt)
}

const CryptoJS = require('crypto-js');  //引用AES源码js

//解密方法
export function aesDecrypt(word, k, i) {
  const key = CryptoJS.enc.Utf8.parse(k);  //十六位十六进制数作为密钥
  const iv = CryptoJS.enc.Utf8.parse(i);
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

//加密方法
export function aesEncrypt(word, k, i) {
  const key = CryptoJS.enc.Utf8.parse(k);  //十六位十六进制数作为密钥
  const iv = CryptoJS.enc.Utf8.parse(i);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.ciphertext.toString();
}

export function aesEncryptA(word, k) {
  const kA = getDesKI(k)
  return aesEncrypt(word, kA[0], kA[1])
}

export function aesDecryptA(word, k) {
  const kA = getDesKI(k)
  return aesDecrypt(word, kA[0], kA[1])
}
