import WechatJSSDK from "wechat-jssdk/dist/client.umd";
import { getWechatConfig, wechatAuth } from "@/api/public";
import { parseQuery } from "@/utils";
import cookie from "@/utils/store/cookie";
import store from "@/store";
import dayjs from "dayjs";
import $store from "@/store";

const STATE_KEY = "wx_authorize_state";
const WX_AUTH = "wx_auth";
const BACK_URL = "login_back_url";
let instance;
let wechatObj;
let wechatLoading = false;


export default function wechat() {
  return new Promise((resolve, reject) => {
    if (instance) return resolve(instance);
    getWechatConfig()
      .then(res => {
        console.log(res)
        const _wx = WechatJSSDK(res.data);
        wechatObj = _wx;
        _wx
          .initialize()
          .then(() => {
            instance = _wx.wx;
            instance.initConfig = res.data;
            console.log('微信授权 构建成功');
            resolve(instance);
          })
          .catch(reject);
      })
      .catch(err => {
        console.log('微信授权 构建失败');
        console.log(err);
        reject(err);
      });
  });
}

export function clearAuthStatus() {
  cookie.remove(WX_AUTH);
  cookie.remove(STATE_KEY);
}
//
// export function oAuth() {
//   if (cookie.has(WX_AUTH) && store.state.app.token) {
//     window.console.log('store.state.app.token')
//     return;
//   }
//
//   const { code } = parseQuery();
//   if (!code) return toAuth();
// }

export async function oAuth() {
  console.log('处理微信授权')
  console.log(store)
  console.log(store.state)
  return new Promise((resolve, reject) => {
    // if (cookie.has(WX_AUTH)) {
    if (cookie.has(WX_AUTH) && store.state.token) {
      reject()
      return;
    }
    const { code } = parseQuery();
    if (!code) {
      toAuth();
      return
    } else {
      auth(code)
    }
    resolve()
  }).catch(error => {
    console.log(error)
  })
}

export function auth(code) {
  console.log('微信授权处理 auth')
  return new Promise((resolve, reject) => {
    let loginType = cookie.get("loginType");
    wechatAuth(code, parseInt(cookie.get("spread")), loginType)
      .then(({ data }) => {
        const expires_time = dayjs(data.expires_time);
        const newTime = Math.round(new Date() / 1000);
        store.commit("LOGIN", data.token, expires_time - newTime);
        cookie.set(WX_AUTH, code, expires_time);
        cookie.remove(STATE_KEY);
        loginType && cookie.remove("loginType");
        resolve();
      })
      .catch(reject);
  });
}

export async function toAuth() {
  console.log('wechatLoading', wechatLoading)
  console.log('$store.state.user.token', $store.state.user.token)
  if (wechatLoading || $store.state.user.token) {
    console.log('正在执行登录操作，toAuth return')
    return
  }
  wechatLoading = true
  wechat().then(wx => {
    console.log('微信授权成功'+ wx)
    location.href = getAuthUrl(wx.initConfig.appId);
  }).catch(e => {
    console.log('微信授权失败', e)
  });
}

export async function toAuth2(next) {
  next({
    path: process.env.VUE_APP_LOADING_PAGE,
  });
}

// 这里获取微信认证跳转路径 这里直接跳转到服务端 服务端再重定向
function getAuthUrl(appId) {
  // const state = encodeURIComponent(`${location.href}`);
  console.log('拼装微信认证跳转链接getAuthUrl $store.state.oauth.redirectUrl', $store.state.oauth.redirectUrl)
  const state = encodeURIComponent($store.state.oauth.redirectUrl);

  let redirect_uri = encodeURIComponent(`https://adminapi.colin.fit/api/wechat/auth`);
  console.log('拼装微信认证跳转链接getAuthUrl redirect_uri', redirect_uri)
  console.log('拼装微信认证跳转链接getAuthUrl state', state)
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}

function toPromise(fn, config = {}) {
  return new Promise((resolve, reject) => {
    fn({
      ...config,
      success(res) {
        resolve(res);
      },
      fail(err) {
        reject(err);
      },
      complete(err) {
        reject(err);
      },
      cancel(err) {
        reject(err);
      }
    });
  });
}

export function pay(config) {
  return toPromise(instance.chooseWXPay, config);
}

export function openAddress() {
  return new Promise((resolve, reject) => {
    wechatEvevt("openAddress", {})
      .then(res => {
        resolve(res);
      })
      .catch(res => {
        if (res.is_ready) {
          res.wx.openAddress({
            fail(res) {
              reject(res);
            },
            success(res) {
              resolve(res);
            }
          });
        } else {
          reject(res);
        }
      });
  });
}

export function openShareAll(config) {
  config || {};
  config.type = config.type == undefined ? "link" : config.type;
  return new Promise(resolve => {
    getWechatConfig().then(res => {
      wechatObj.signSignature({
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        timestamp: res.data.timestamp
      });
      instance = wechatObj.getOriginalWx();
      instance.ready(() => {
        instance.updateAppMessageShareData(config);
        instance.updateTimelineShareData(config);
        resolve();
      });
    });
  });
}

export function openShareAppMessage(config) {
  instance.updateAppMessageShareData(config);
  instance.onMenuShareAppMessage && instance.onMenuShareAppMessage(config);
}

export function openShareTimeline(config) {
  instance.updateTimelineShareData(config);
  instance.onMenuShareTimeline && instance.onMenuShareTimeline(config);
}

export function wechatEvevt(name, config) {
  window.console.log('222222222233333334')
  return new Promise((resolve, reject) => {
    let wx;
    let configDefault = {
      fail(res) {
        window.console.log('fail configDefault')
        console.log(res);
        if (wx) return reject({ is_ready: true, wx: wx });
        getWechatConfig().then(res => {
          window.console.log(res)
          window.console.log('fail getWechatConfig' + res)
          wechatObj.signSignature({
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            timestamp: res.data.timestamp
          });
          wx = wechatObj.getOriginalWx();
          window.console.log('wechatObj.getOriginalWx：' + wx)
          reject({ is_ready: true, wx: wx });
        });
      },
      success(res) {
        window.console.log('success configDefault')
        resolve(res);
      }
    };
    Object.assign(configDefault, config);
    if (typeof instance !== "undefined") {
      window.console.log('typeof instance !== "undefined"')
      instance.ready(() => {
        if (typeof name === "object") {
          name.forEach(item => {
            instance[item] && instance[item](configDefault);
          });
        } else instance[name] && instance[name](configDefault);
      });
    } else {
      window.console.log('typeof instance !== undefined else')
      getWechatConfig().then(res => {
        const _wx = WechatJSSDK(res.data);
        _wx.initialize().then(() => {
          window.console.log('_wx.initialize().')
          instance = _wx.getOriginalWx();
          instance.ready(() => {
            window.console.log('instance.ready(() ')
            if (typeof name === "object") {
              name.forEach(item => {
                instance[item] && instance[item](configDefault);
              });
            } else instance[name] && instance[name](configDefault);
          });
        });
      });
    }
  });
}

export function ready() {
  return new Promise(resolve => {
    window.console.log('ready new Promise(resolv')
    if (typeof instance !== "undefined") {
      instance.ready(() => {
        resolve(instance);
      });
    } else {
      window.console.log('ready new Promise(resolv else')
      getWechatConfig().then(res => {
        window.console.log('ready new Promise(resolv getWechatConfig')
        const _wx = WechatJSSDK(res.data);
        _wx.initialize().then(() => {
          instance = _wx.wx;
          instance.ready(() => {
            window.console.log('ready new Promise(resolv getWechatConfig ready')
            resolve(instance);
          });
        });
      });
    }
  });
}

// export function wxShowLocation() {
//   return new Promise(() => {
//     wechatEvevt("getLocation", { type: "wgs84" })
//       .then(res => {
//         let latitude = res.latitude; // 纬度
//         let longitude = res.longitude; // 经度
//         cookie.set(LATITUDE, latitude);
//         cookie.set(LONGITUDE, longitude);
//       })
//       .catch(res => {
//         if (res.is_ready) {
//           res.wx.getLocation({
//             success(res) {
//               let latitude = res.latitude; // 纬度
//               let longitude = res.longitude; // 经度
//               cookie.set(LATITUDE, latitude);
//               cookie.set(LONGITUDE, longitude);
//             },
//             cancel() {
//               cookie.remove(LATITUDE);
//               cookie.remove(LONGITUDE);
//               this.$dialog.error("取消获取位置");
//             },
//             fail() {
//               cookie.remove(LATITUDE);
//               cookie.remove(LONGITUDE);
//               this.$dialog.error("授权失败");
//             }
//           });
//         }
//       });
//   });
// }
