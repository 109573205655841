import axios from 'axios'
import Config from '@/settings'
import {handleLogin} from "@/utils/admin/index";
import $store from "@/store";
import {Notify} from "vant";
import {aesDecrypt, aesEncrypt, aesEncryptA, aesDecryptA, decrypt, decryptL, encrypt, encryptL} from "@/utils/admin/rsaEncrypt";


// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_ADMIN_API_URL, // api 的 base_url
    timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        const token = $store.state.user.token
        console.log('请求拦截，获取token')
        if (token) {
            console.log('请求拦截，获取token，加入请求头')
            config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers['Content-Type'] = 'application/json'
        console.log(config)
        // rsa方式
        // config.headers['enable-encrypt'] = true
        // if (config.data) {
        //     const jsonS = JSON.stringify(config.data)
        //     console.log('请求需要加密')
        //     console.log('请求需要加密 jsonS', jsonS)
        //     console.log('请求需要加密 encodeURIComponent', encodeURIComponent(jsonS))
        //     console.log('请求需要加密 encodeURI', encodeURI(jsonS))
        //     // config.data = encryptL(encodeURIComponent(JSON.stringify(config.data)))
        //     config.data = encryptL(encodeURIComponent(jsonS))
        //     console.log('请求需要加密 data', config.data)
        // }

        // aes方式
        // config.headers['enable-encrypt'] = true
        // if (config.data) {
        //     const jsonS = JSON.stringify(config.data)
        //     config.data = aesEncryptA(encodeURIComponent(jsonS), sessionStorage.getItem("encrypt-ak"))
        // }

        // config.headers['enable-encrypt'] = true
        // if (config.data) {
        //     console.log('请求需要加密', JSON.stringify(config.data))
        //     console.log('请求需要加密 encodeURIComponent', encodeURIComponent(JSON.stringify(config.data)))
        //     // const base64 = btoa(encodeURIComponent(JSON.stringify(config.data)))
        //     const base64 = btoa(encodeURI(JSON.stringify(config.data)))
        //
        //     config.data = encrypt(JSON.stringify(config.data))
        //
        //     console.log('请求需要加密 encodeURIComponent', decrypt(config.data))
        //
        //     // config.data = encrypt(encodeURIComponent(JSON.stringify(config.data)))
        // }
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const code = response.status
        console.log('admin 请求类 $store.state.user.token', $store.state.user.token)
        console.log('admin 请求类 response', response)
        if (code < 200 || code > 300) {
            Notification.error({
                title: response.message
            })
            return Promise.reject('error')
        } else {
            let result;
            if (response.headers['encrypt-body'] === 'true') {
                result = JSON.parse(decodeURIComponent(aesDecryptA(response.data, response.headers['encrypt-ak'])))
            } else {
                result = response.data
            }
            sessionStorage.setItem("encrypt-ak", response.headers['encrypt-ak']);
            return result
        }
    },
    error => {
        console.log('admin 请求类 response error', error)
        if (error.response.data.message !== null && error.response.data.message !== '' && error.response.data.message !== undefined) {
            Notify({type: 'danger', message: error.response.data.message});
        }
        let code = 0
        try {
            code = error.response.data.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                Notify({type: 'danger', message: '网络请求超时'});
                return Promise.reject(error)
            }
        }
        if (code) {
            if (code === 401) {
                console.log('接口返回401 开始执行登录操作')
                handleLogin()
            } else if (code === 403) {
                // router.push({ path: '/401' })
            } else {
                const errorMsg = error.response.data.message
                if (errorMsg !== undefined) {
                    Notify({type: 'danger', message: errorMsg});
                }
            }
        } else {
            Notify({type: 'danger', message: '接口请求失败'})
        }
        return Promise.reject(error)
    }
)
export default service
