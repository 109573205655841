import $store from "@/store";
import EventBus from "vertx3-eventbus-client";
import Vue from 'vue'
import {Toast} from "vant";
import {curUserId} from "@/utils/admin/auth";

export function createConnect() {
    console.log('Vertx EventBus mounted', $store.getters.authUser.id)
    let address = process.env.VUE_APP_VERTX_SOCKET_URL
    console.log('Vertx EventBus mounted address', address)

    let eb = new EventBus(address)
    Vue.prototype.$eventBus = eb
    eb.onclose = function () {
        console.log('eb closed!!!')
    }
    eb.onopen = function () {
        //注册监听器用来接受消息
        console.log('eb open!!!')
        eb.registerHandler('orderchange-1', function (error, message) {
            console.log('received a message: ' + JSON.stringify(message));
        });
        eb.registerHandler('vertx-socket-js-open-notice', function (error, message) {
            console.log('received a openNotice message: ', {message});
            $store.commit('setVertxNoticeText', message.body);
        });
        let userId = curUserId()
        //发送消息
        eb.send('vertx-socket-js-in-user-connect',
            {},
            {
                userId: userId,
            },
            (err, reply) => {
                if (err !== null) {
                    Toast('连接通知服务器失败');
                } else {
                    Toast('连接通知服务器成功～ ' + reply.body);
                }
            });
    }
    // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
    window.onbeforeunload = function () {
        console.log('Vertx EventBus onbeforeunload 关闭')
        eb.close()
    }
    eb.enableReconnect(true);
}

