const getters = {
  token: state => state.user.token,
  roles: state => state.user.roles,
  user: state => state.user.user,
  authUser: state => state.user.authUser,
  loadMenus: state => state.user.loadMenus,
  isAuthorizationPage: state => state.user.isAuthorizationPage,
  isAuthorization: state => state.user.isAuthorization,
  product: state => state.order.product,
  products: state => state.order.products,
  mergeProducts: state => state.order.mergeProducts,
  amount: state => state.order.amount,
  orderUser: state => state.order.orderUser,
  popupCate: state => state.order.cate,
  mapInfo: state => state.map.mapInfo,
  accountUser: state => state.user.accountUser,
  socketAction: state => state.im.socketAction,
  selectChatUserId: state => state.im.selectChatUserId,
  // refreshContactList: state => state.im.refreshContactList,
  refreshContactAction: state => state.im.refreshContactAction,
}
export default getters
